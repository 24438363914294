<template>
  <transition-group name="fade" tag="ul">
    <connector
      v-for="(item, index) in connectors.slice(0, limit)"
      :key="`${item.name}-${index}`"
      :element="item"
    />
  </transition-group>
</template>

<script>
import Connector from './Connector'
import _ from 'underscore'

export default {
  components: {
    Connector
  },
  props: {
    rawConnectors: { default: null, type: Array },
    family: { default: () => [], type: Array },
    area: { default: () => [], type: Array },
    country: { default: () => [], type: Array },
    fluids: { default: () => [], type: Array },
    limit: { default: 1000, type: Number },
    searchQuery: { default: '', type: String }
  },
  computed: {
    connectors() {
      let connectorsToDisplay = [...this.rawConnectors]

      connectorsToDisplay = connectorsToDisplay.filter(connector =>
      this.connectorInArea(connector) &&
      this.connectorInCountry(connector) &&
      this.connectorWithUtility(connector) &&
      this.connectorWithFamily(connector)
      )

      if (this.searchQuery.trim() !== '') {
        connectorsToDisplay = connectorsToDisplay.filter(connector =>
          connector.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      }
      return _.sortBy(connectorsToDisplay, 'name')
    },
  },
  watch: {
    connectors() {
      this.$emit('connectors-range', this.connectors.length)
    }
  },
  methods: {
    connectorInArea(connector) {
      if (!(this.area && this.area.length)) {
        return true
      }
      const area = Array.isArray(connector.area) ? connector.area : [connector.area]
      return area.some(a => this.area.includes(a))
    },
    connectorInCountry(connector) {
      if (!(this.country && this.country.length)) {
        return true
      }
      const country = Array.isArray(connector.country) ? connector.country : [connector.country]
      return country.some(c => this.country.includes(c))
    },
    connectorWithUtility(connector) {
      if (!(this.fluids && this.fluids.length)) {
        return true
      }
      const fluids = Array.isArray(connector.fluids) ? connector.fluids : [connector.fluids]
      return fluids.some(fl => this.fluids.includes(fl))
    },
    connectorWithFamily(connector) {
      if (!(this.family && this.family.length)) {
        return true
      }
      const family = Array.isArray(connector.family) ? connector.family : [connector.family]
      return family.some(fam => this.family.includes(fam))
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(-1000px);
}
</style>
